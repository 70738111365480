.form-LoginForm {
    width: 100%;
    max-width: 900px;
    padding:20px;
    margin-right: auto;
    margin-left: auto;
 }
.box-LoginForm{
    display: flex;
    justify-content: center;     
}

/*Login Form Box Styles*/
.container-LoginForm {
    width: 20rem;
    font-size: 1.25rem;
    padding: 10px 20px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* flex-direction: column;
    align-items: center; */
}
@media screen and (min-width:1280px){
    .container-LoginForm{
        width: 25rem;
    }
}

/*Logo MKM Style*/
.logo-container{
    display: flex;
    justify-content: center;
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column-reverse;
}
.logo-mkm:not(#logo-sidebar){
    padding: 10px;
    width: 180px;
    margin: auto;
}

/*Judul Login MKM*/
.judul-login{
    text-align: center;
    padding-bottom: 15px;
    padding-top: 5px;
    font-weight: 500;
    font-size: 18px;
}

/*Eye Logo Style*/
.eye-logo{
    float: right;
    margin-right: 15px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    bottom: 35px;
    width: 1rem;
}
.eye-logoChild{
    filter: invert(82%) sepia(9%) saturate(102%) hue-rotate(1deg) brightness(87%) contrast(85%);
}
.black{
    filter: invert(0%) sepia(11%) saturate(7482%) hue-rotate(289deg) brightness(98%) contrast(94%);
}

/*Toast Style*/
.container-Toast{
    display: flex;
    justify-content: center;
}

/* Button LoginForm */
#btn-container-login{
    margin-top: 5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
#btn-loginForm{
    margin-top: 15px;
    width: 100% !important;
}

/* Spinner Animation */
.spinner-border{
    width: 1.25rem !important;
    height:1.25rem !important;
}

@media (max-width: 575px) {
    .logo-mkm:not(#logo-sidebar) {
        width: 150px;
    }
    .container-LoginForm {
        width: 70%;
    }
    .form-LoginForm .btn-text {
        font-size: 0.9rem;
    }
    #btn-container-login {
        margin-top: 1.5rem;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .container-LoginForm {
        width: 55%;
    }
    #btn-container-login {
        margin-top: 2rem;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    .container-LoginForm {
        width: 60%;
    }
    #btn-container-login {
        margin-top: 2rem;
    }
}