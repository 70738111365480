.accordion{
    padding: 80px;
}
.box-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-deck{
    margin-bottom: 15px;
}
.card-header{
    background-color: var(--main-color);
    color: white;
}
.card-title{
    margin: 0 !important;
}


/*Icon Grafik*/
.icon-grafik{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    margin: auto;
    color: white;
    align-items: center;
}
.icon-grafik.red{
    background-color: var(--main-color);
}
.icon-grafik.blue{
    background-color: #17a2b8;
}


/*Progress Bar*/
.progress{
    height: 35px;
}
.progress-bar{
    color: black;
}

@media (max-width: 575px) {
    .accordion {
        padding: 20px;
    }
    .storage-page .card-header .card-title {
        font-size: 0.8em;
    }
    .storage-page .card-body .col-sm-8 {
        padding: 0;
        padding-left: 5px;
    }
    .storage-page .card-body h5 {
        font-size: 0.75rem;
    }
    .storage-page .card-body p {
        font-size: 0.7rem;
    }
    .icon-grafik {
        width: 40px;
        height: 40px;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .accordion {
        padding: 35px;
        padding-left: 0;
    }
    .storage-page .card-header .card-title {
        font-size: 0.8em;
    }
    .storage-page .card-body .col-sm-8 {
        padding: 0;
        padding-left: 5px;
    }
    .storage-page .card-body h5 {
        font-size: 0.75rem;
    }
    .storage-page .card-body p {
        font-size: 0.7rem;
    }
    .icon-grafik {
        width: 40px;
        height: 40px;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    .accordion {
        padding: 55px;
        padding-left: 20px;
    }
    .storage-page .card-header .card-title {
        font-size: 0.9em;
    }
    .storage-page .card-body .col-sm-8 {
        padding: 0;
        padding-left: 5px;
    }
    .storage-page .card-body h5 {
        font-size: 0.9rem;
    }
    .storage-page .card-body p {
        font-size: 0.75rem;
    }
    .icon-grafik {
        width: 40px;
        height: 40px;
    }
}

@media (min-width: 991px) and (max-width: 1280px) {
    .storage-page .card-body .col-sm-8 {
        padding: 0;
        padding-left: 5px;
    }
    .storage-page .card-body h5 {
        font-size: 1rem;
    }
    .storage-page .card-body p {
        font-size: 0.9rem;
    }
    .icon-grafik {
        width: 45px;
        height: 45px;
    }
}