.home{
  width: 100%;
}
h4 {
  padding: 10px;
  position: fixed;
  top: 0%;
  box-shadow: 1px 2px 8px black;
  z-index: 999;
}
em {
  color: grey;
}
label {
  margin: 0 !important;
}
a {
  text-decoration: none;
  color: black;
}
a:link {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
#color{
  color: yellow;
}