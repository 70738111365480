/*Form Styles*/
.form {
    width: 100%;
    max-width: inherit;
 }
.container-form{
    padding:30px;
    font-size: 1rem;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
textarea{
    max-height: 200px;
    min-height: 100px;
}


/*Card*/
.card-form{
    width: 500px;
    margin-top: 40px;
    box-shadow: 2px 2px 8px;
}
.card-data{
    box-shadow: 2px 2px 8px;
}
.card:not(.card-detail){
    border: none !important;
}


/*Error Message*/
.error_info{
    color: red;
    font-size: 1rem;
}


/*Button Styles*/
.btn-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.btn:not(#btn-loginForm){
    width: 100%;
    max-width: 130px;
}
.btn-container.gantiPassword{
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}
.btn-secondary{
    float: left;
    background-color: white !important;
    color: var(--main-color) !important;
}

/*Autocomplete*/
.container-suggestions{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
}
.suggestions {  
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 175px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    text-align: left;
} 
.suggestions li {
    padding: 0.2rem 0.5rem;
    font-size: 1rem;
}
.suggestion-active,
.suggestions li:hover {
  cursor: pointer;
}

/* Error Message */
.error_info, #text{
    color: red;
    font-size: 1rem;
}

/* Unauthorized */
.unauthorize-container{
    text-align: center;
    height: 365px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px;
    background: #e9e9e9;
    box-shadow: 1px 2px 8px black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.unauthorize-container h1:hover{
    color: var(--main-color);
    cursor: pointer;
}
.unauthorize-container h1{
    transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}
#logo-unauthorized{
    border-right: 5px solid var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Toast */
.toast_container{
    position:fixed;
    bottom: 0;
    right: 0;
    margin-right:15px;
    margin-bottom:40px;
    z-index: 1;
}
.success > .toast-header{
    background-color: aquamarine;
}
.error > .toast-header{
    background-color: brown;
}
.warning > .toast-header{
    background-color: yellow;
}
.unknown > .toast-header{
    background-color: blueviolet;
}
.Toast-Body{
    text-align:center;
}


/* Ganti Password */
.main-gantiPassword{
    box-shadow: 1px 2px 8px black;
}
.main.gantiPassword{
    justify-content: center;
    align-items: center;
}

@media (max-width: 575px) {
    .container .form-group {
        margin-bottom: 0.2rem;
    }
    .container-image {
        display: block;
        margin-top: 1rem;
    }
    .form-image {
        padding: 0;
    }
    .form-image .btn-image img {
        width: 150px !important;
        height: 150px !important;
    }
    .home .main .container h4,
    .home .main .container-form h4 {
        font-size: 0.5em;
    }
    .form-group .form-label,
    .form-group .form-control,
    .custom-file-label,
    .btn:not(.btn-secondary) {
        font-size: 0.6em;
    }
    .container-form .card-form.card {
        width: 100%;
    }
    .container-form .card-header h5 {
        font-size: 0.7em;
        margin-bottom: 0;
    }
    .detail-aset-form {
        justify-content: start !important;
    }
    .container.delivery-form {
        padding: 0;
        margin-top: 60px;
    }
    #row-pengiriman {
        width: 100%;
    }
    .logo-container-pengiriman,
    .form-pengiriman {
        padding: 10px 15px;
    }
    .form-pengiriman .row .col-table {
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
    }
    .logo-container-pengiriman h3 {
        font-size: 0.7rem;
        text-align: center;
    }
    .logo-container-pengiriman h5 {
        font-size: 0.65rem;
        text-align: center;
    }
    .logo-mkm-pengiriman {
        width: 60%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .detail-aset-form.nama-jasa {
        padding-right: initial;
        margin-top: 5px;
    }
    .nama-jasa label {
        width: 100%;
        text-align: left;
    }
    .form-group .label-pengiriman {
        padding-top: 0;
        padding-right: 0;
    }
    .form-group .form-control-plaintext {
        font-size: 0.5em !important;
        width: 100%;
        min-width: unset !important;
        padding: 3px 5px !important;
    }
    .form-footer-container {
        width: 100% !important;
        margin: 30px 35px 20px !important;
    }
    .form-footer-container .btn-primary,
    .form-footer-container .btn-secondary {
        font-size: 0.55em;
        padding: 8px 0;
        min-width: 65px;
    }
    .dropdown-menu.show .dropdown-item {
        font-size: 0.5em;
    }
    .input-group-prepend span.input-group-text,
    .input-group-append span.input-group-text {
        font-size: 0.5em;
    }
    .main-gantiPassword {
        margin-left: 50px;
        margin-right: 50px;
    }
    .main-gantiPassword .card-header {
        padding: 5px;
    }
    .main-gantiPassword .card-header h1 {
        font-size: 0.7em !important;
        padding: 0 !important;
        padding-top: 10px !important;
    }
    .main-gantiPassword .card-body,
    .main-gantiPassword .card-footer {
        padding: 5px;
        padding-bottom: 15px;
    }
    .form-LoginForm.gantiPassword,
    .logo-container.gantiPassword {
        max-width: 100%;
    }
    .logo-container.gantiPassword h1 {
        margin-bottom: 0;
        font-size: 0.6em !important;
        padding: 10px !important;
    }
    .form-LoginForm.gantiPassword {
        padding: 10px 15px 15px;
        font-size: 0.65em;
    }
    .form-group-GantiPassword > input {
        padding: 5px 10px !important;
        height: auto !important;
        font-size: 0.65em;
    }
    .form-group-GantiPassword.form-group {
        margin-bottom: 0.2rem;
    }
    .btn-container.gantiPassword .btn-primary,
    .btn-container.gantiPassword .btn-secondary {
        font-size: 0.6em;
        margin-left: 5px;
        margin-right: 5px;
    }
    .btn-container.gantiPassword {
        margin-top: 15px;
    }
    .btn-container.gantiPassword button {
        min-width: 60px;
    }
    .form-LoginForm.gantiPassword .eye-logo {
        top: 2px;
        font-size: 0.6rem;
    }
    h3.detail-aset {
        font-size: 0.6rem;
        margin-bottom: 3px;
    }
    h5.detail-aset, h6.detail-aset {
        font-size: 0.6rem;
        margin-bottom: 3px;
    }
    .jenis-barang h6 {
        font-size: 0.5rem;
    }
    .unauthorize-container h1 {
        font-size: 1.5rem !important;
        margin-right: 5px;
    }
    #logo-unauthorized {
        border-right: none !important;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .error_info{
        font-size: 0.6em;
        margin-bottom: 0;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .main-gantiPassword {
        margin-left: auto;
        margin-right: auto;
    }
    .main-gantiPassword .card-header h1 {
        font-size: 0.85em !important;
        padding: 0 !important;
        padding-top: 10px !important;
    }
    .container-form.form-storage,
    .container-form.form-inside-page {
        padding-left: 0;
    }
    .container-form .card-form.card {
        width: 100%;
    }
    .container-form.form-page {
        padding-left: 0;
        margin-left: -5px;
        margin-bottom: 5vh;
    }
    .container-form .card-header h5 {
        font-size: 0.9em;
        margin-bottom: 0;
    }
    .container .form-group {
        margin-bottom: 0.5rem;
    }
    .container-image {
        margin-top: 1rem;
    }
    .home .main .container h4,
    .home .main .container-form h4 {
        font-size: 0.7em;
    }
    .form-group .form-label,
    .form-group .form-control,
    .custom-file-label,
    .btn-container .btn:not(.btn-secondary) {
        font-size: 0.75em;
    }
    .container.delivery-form {
        padding: 0;
        margin-top: 60px;
    }
    #row-pengiriman {
        width: 100%;
    }
    .logo-mkm-pengiriman {
        width: 60%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .logo-container-pengiriman,
    .form-pengiriman {
        padding: 15px 20px;
    }
    .form-pengiriman .row .col-table {
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
    }
    .logo-container-pengiriman h3 {
        font-size: 1rem;
    }
    .logo-container-pengiriman h5 {
        font-size: 0.8rem;
    }
    .form-group .form-control-plaintext {
        font-size: 0.7em !important;
        width: 100%;
    }
    .form-footer-container {
        width: 100% !important;
        margin: 30px 35px 20px !important;
        display: block !important;
    }
    .form-footer-container .btn-primary,
    .form-footer-container .btn-secondary {
        width: 100%;
        font-size: 0.75em;
        margin-bottom: 5px;
    }
    .dropdown-menu.show .dropdown-item {
        font-size: 0.7em;
    }
    .input-group-prepend span.input-group-text,
    .input-group-append span.input-group-text {
        font-size: 0.7em;
    }
    .form-LoginForm.gantiPassword,
    .logo-container.gantiPassword {
        max-width: 100%;
    }
    .logo-container.gantiPassword h1 {
        margin-bottom: 0;
        font-size: 0.7em !important;
        padding: 10px !important;
    }
    .form-LoginForm.gantiPassword {
        padding: 15px 25px 20px;
        font-size: 0.7em;
    }
    .form-group-GantiPassword > input {
        padding: 5px 10px !important;
        height: auto !important;
        font-size: 0.7em;
    }
    .btn-container.gantiPassword .btn-primary,
    .btn-container.gantiPassword .btn-secondary {
        font-size: 0.8em;
    }
    h3.detail-aset {
        font-size: 0.9rem;
    }
    h5.detail-aset, h6.detail-aset {
        font-size: 0.8rem;
    }
    .jenis-barang h6 {
        font-size: 0.7rem;
    }
    .btn-container.gantiPassword {
        margin-top: 20px;
    }
    .btn-container.gantiPassword button {
        min-width: 80px;
    }
    .form-LoginForm.gantiPassword .eye-logo {
        top: 0;
    }
    .unauthorize-container h1 {
        font-size: 1.5rem !important;
        margin-right: 5px;
    }
    .error_info{
        font-size: 0.65em;
        margin-bottom: 0;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    .container-form.form-page {
        padding-left: 0;
        margin-left: -5px;
    }
    .container-form .card-header h5 {
        font-size: 1.1em;
        margin-bottom: 0;
    }
    .home .main .container h4,
    .home .main .container-form h4 {
        font-size: 0.8em;
    }
    .form-group .form-label,
    .form-group .form-control {
        font-size: 0.85em;
    }
    .custom-file-label {
        font-size: 0.9em;
    }
    .btn-container .btn:not(.btn-secondary) {
        font-size: 0.9em;
    }
    .container.delivery-form {
        padding: 0;
        margin-top: 60px;
    }
    #row-pengiriman {
        width: 100%;
    }
    .logo-container-pengiriman,
    .form-pengiriman {
        padding: 25px 30px;
    }
    .logo-container-pengiriman h3 {
        font-size: 1.2rem;
    }
    .logo-container-pengiriman h5 {
        font-size: 1rem;
    }
    .form-group .form-control-plaintext {
        font-size: 0.8em;
        width: 100%;
    }
    .form-footer-container {
        width: 100% !important;
        margin: 30px 35px 20px !important;
    }
    .form-footer-container .btn-primary,
    .form-footer-container .btn-secondary {
        font-size: 0.9em;
    }
    .dropdown-menu.show .dropdown-item {
        font-size: 0.85em;
    }
    .input-group-prepend span.input-group-text,
    .input-group-append span.input-group-text {
        font-size: 0.85em;
    }
    .main-gantiPassword {
        margin-left: auto;
        margin-right: auto;
    }
    .main-gantiPassword .card-header h1 {
        font-size: 0.85em !important;
        padding: 0 !important;
        padding-top: 10px !important;
    }
    .form-LoginForm.gantiPassword,
    .logo-container.gantiPassword {
        max-width: 100%;
    }
    .logo-container.gantiPassword h1 {
        margin-bottom: 0;
        font-size: 0.85em !important;
        padding: 15px !important;
    }
    .form-LoginForm.gantiPassword {
        padding: 15px 25px 25px;
    }
    .form-group-GantiPassword > input {
        padding: 5px 10px !important;
        height: auto !important;
    }
    .btn-container.gantiPassword .btn-primary,
    .btn-container.gantiPassword .btn-secondary {
        font-size: 0.9em;
    }
    h3.detail-aset {
        font-size: 1.3rem;
    }
    h5.detail-aset {
        font-size: 1.1rem;
    }
    .unauthorize-container {
        width: 70vw;
    }
    .unauthorize-container h1 {
        font-size: 2rem !important;
    }
    .unauthorize-container h2 {
        font-size: 1.6rem !important;
    }
    .error_info{
        font-size: 0.75em;
        margin-bottom: 0;
    }
}

@media (min-width: 991px) and (max-width: 1280px) {
    .main.gantiPassword {
        margin-left: 20px;
    }
    .main.gantiPassword .card-header h1 {
        padding-top: 10px !important;
        padding-bottom: 0 !important;
    }
    .home .main .container h4,
    .home .main .container-form h4 {
        font-size: 0.9em;
    }
    .main-gantiPassword {
        margin-left: 300px;
    }
    .form-LoginForm.gantiPassword,
    .logo-container.gantiPassword {
        max-width: 35vw;
    }
    .unauthorize-container h1 {
        font-size: 1.8rem !important;
    }
    .unauthorize-container h2 {
        font-size: 1.6rem !important;
    }
    .error_info{
        font-size: 0.9em;
        margin-top: -5px;
    }
}