.sidenav{
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    box-shadow: 2px 2px 8px;
    line-height: 5vh;
}
.sidenav a {
    padding: 6px 6px 6px 20px;
    text-decoration: none;
    font-size: 16px;
    display: block;
}
.sidenav a:hover{
    text-decoration: none;
}
.main{
    margin-left: 300px;
    margin-right: 0px !important;
    height: 100vh;
}
#logo-sidebar{
    width: 170px;
    padding: 15px;
    margin: auto;
}
.content-sidebar{
    height: calc(100% - 290px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    scrollbar-color: orange;
    scrollbar-width: 5px;

}
.title-submenu{
    padding: 6px 6px 6px 10px;
    color: grey;
    margin: 0;
}
.submenu> a{
    padding: 6px 6px 6px 30px
}

@media screen and (max-height: 467px){
    .sidenav a{
        font-size: 12px;
    }
    #logo-sidebar{
        width: 80px;
    }
}

/* Button Logout */
.btn-logout-container{
    position: absolute;
    bottom: 0;
    align-items: center;
    max-height: 150px;
    height: 100%;
    border-top:2px solid var(--main-color);
    width: 100%;
}
.button-bottom-container{
    margin-bottom: 3vh;
}

/* Icon Sidebar */
.icon-sidebar{
    margin-right: 20px;
}

/* Copyright style */
.copyright-container{
    padding: 6px 20px 6px 20px;
    font-size: 16px;
    background-color: #e9e9e9;
    color: black;
    word-break: break-all;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100%;
    bottom: 0;
    position: absolute;
}

/* Button Refresh */
.button-refresh{
    background: transparent;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.button-refresh>button{
    width: 15vw;
}

@media (max-width: 575px) {
    /* Sidebar */
    .sidenav {
        width: 100px;
    }
    #logo-sidebar {
        width: 80px;
    }
    .sidenav a {
        padding: 5px;
        font-size: 9px;
        line-height: 1rem;
    }
    .sidenav a svg {
        margin-right: 10px;
    }
    .title-submenu {
        font-size: 0.65em;
    }
    .copyright-container {
        padding: 0;
        font-size: 7px;
    }
    /* Content */
    .main {
        margin-left: 105px;
        margin-right: 20px;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    /* Sidebar */
    .sidenav {
        width: 150px;
    }
    #logo-sidebar {
        width: 120px;
    }
    .sidenav a {
        padding: 8px 15px;
        font-size: 12px;
        line-height: 25px;
    }
    .sidenav a svg {
        margin-right: 10px;
    }
    .title-submenu {
        font-size: 0.85em;
    }
    .copyright-container {
        padding: 0;
        font-size: 10px;
    }
    /* Content */
    .main {
        margin-left: 185px;
        margin-right: 30px;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    /* Sidebar */
    .sidenav {
        width: 180px;
    }
    #logo-sidebar {
        width: 140px;
    }
    .sidenav a {
        padding: 10px 0 10px 15px;
        font-size: 14px;
        line-height: 20px;
    }
    .title-submenu {
        font-size: 1em;
    }
    .copyright-container {
        padding: 15px 8px;
        font-size: 12px;
    }
    /* Content */
    .main {
        margin-left: 220px;
        margin-right: 35px;
    }
}